// 开发环境域名
// https://php-b2csaas.yixiangonline.com
const host_development = 'https://mall.378361.cn'

export default {
  // 版本
  version: '2.6.1',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
