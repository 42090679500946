
import { Component, Prop, Vue } from 'vue-property-decorator'
import { asyncRoutes } from '@/router'
import { State } from 'vuex-class'

@Component
export default class Menu extends Vue {
    @Prop({
        default: () => {
            return []
        }
    })
    menu!: any[]

    @State('user') user: any;

    get activePath() {
        return this.$route.meta?.parentPath
    }

    get menuList() {
        return asyncRoutes
    }

    get userInfo() {
        return this.user.userInfo
    }

    get logo() {
        return this.$store.getters.config.platform_logo
    }
}
