
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import store from './store'

@Component
export default class App extends Vue {
  // @Action('getPermission') getPermission!: () => void
  @Action('getConfig') getConfig!: () => void
  async created() {
      // this.getPermission()

      await this.getConfig()
      document.title = store.getters.config.platform_name

      // 动态设置站点图片
      const link = this.$store.getters.config.platform_ico
      let $favicon: any = document.querySelector('link[rel="icon"]')
      if ($favicon !== null) {
          $favicon.href = link
      } else {
          $favicon = document.createElement('link')
          $favicon.rel = 'icon'
          $favicon.href = link
          document.head.appendChild($favicon)
      }
  }
}
