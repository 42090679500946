import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
  {
    path: '/channel',
    name: 'channel',
    meta: { title: '渠道', icon: 'el-icon-mobile' },
    redirect: '/channel/mp_wechat',
    component: Main,
    children: [{
      path: '/channel/mp_wechat',
      name: 'mp_wechat_index',
      meta: {
        title: '渠道设置',
        icon: 'icon_qudao_weixin',
        parentPath: '/channel',
        permission: ['view']
      },
      component: () => import('@/views/channel/mp_wechat/index.vue')
    }]
  }
]

export default routes
