
import { Component, Prop, Vue } from 'vue-property-decorator'
import { asyncRoutes } from '@/router'
import { State } from 'vuex-class'

@Component
export default class Aside extends Vue {
    @Prop() readonly meta!: any[]
    @Prop({
      default: () => {
        return []
      }
    })
    menu!: any[]

    @State('user') user: any;

    get menuList () {
      return asyncRoutes
    }

    get userInfo () {
      return this.user.userInfo
    }

    get activePath () {
      return this.$route.meta?.prevPath || this.$route.path
    }
}
